<template>
  <div class="contianer">
      <div class="head">
          <div class="title">公司名称</div>
          <el-input v-model="options.companyName" size="small" placeholder="请输入"></el-input>
          <el-button type="primary" size="medium" @click="search">搜索</el-button>
          <el-button type="warning" size="medium" @click="reset">重置</el-button>
      </div>
      <div class="table-box">
          <table>
              <tr class="head-tr">
                  <td style="width:5%">序号</td>
                  <td style="width:20%">公司名称</td>
                  <td style="width:12%">联系电话</td>
                  <td>内容</td>
                  <td style="width:12%">操作</td>
              </tr>
              <tr v-for="(item,i) in list" :key="i">
                  <td><span :style="{display:item.id?'':'none'}">{{i+1}}</span></td>
                  <td title="公司名称">{{item.companyName}}</td>
                  <td>{{item.phone}}</td>
                  <td>{{item.content}}</td>
                  <td>
                      <span :style="{display:item.id?'':'none'}">
                        <i @click="edit(item.id)" class="el-icon-chat-line-square" style="font-size:20px;color:#409EFF;cursor:pointer"></i>
                        <i @click="deleteQuestions(item.id)" class="el-icon-delete" style="margin-left:10%;font-size:20px;color:red;cursor:pointer"></i>
                      </span>
                  </td>
              </tr>
          </table>
      </div>
      <div class="footer">
          <div @click="prev">上一页</div>
          <p>{{pageCurrent}} / {{pageToatal}}</p>
          <div @click="next">下一页</div>
      </div>
      <el-dialog
        title="回 复"
        :visible.sync="dialogVisible"
        width="50%"
        :close-on-click-modal="false"
        center>
        <div class="dia-table-box">
            <table>
                <tr class="head-tr">
                    <td style="width:5%">序号</td>
                    <td>历史回复</td>
                    <td style="width:20%">操作</td>
                </tr>
                <tr v-for="(item,i) in replyList" :key="i">
                    <td>{{i+1}}</td>
                    <td :title="item.content">{{item.content}}</td>
                    <td><i @click="deleteReply(item.id)" class="el-icon-delete"></i></td>
                </tr>
            </table>
        </div>
        <div class="dialog-form">
            <div class="title">回复内容</div>
            <textarea rows="3" v-model="content"/>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button style="margin-left:10%" type="primary" @click="dialogCommit">确 定</el-button>
        </span>
        </el-dialog>
  </div>
</template>

<script>
export default {
    name: 'replyManagement',
    data(){
        return{
            options:{
                pageSize:15,
                pageIndex:1,
                companyName:'',
            },
            dialogVisible:false,
            list:[],
            total:0,
            pageToatal:1,
            pageCurrent:1,
            content:'',
            questionsId:-1,
            replyList:[],
        }
    },
    methods:{
        //获取问题列表
        getQuestions(options){
            this.$api.getQuestions(options).then(res=>{
                 if(res.data.code == 100){
                    this.list.push({
                        companyName:'',
                        phone:'',
                        content:'',
                    })
                    return
                }else{
                    if(res.data.data.length < 15) {
                        this.list = res.data.data;
                        for(let i=res.data.data.length; i<15; i++){
                            this.list.push({
                                companyName:'',
                                phone:'',
                                content:'',
                            })
                        }
                    }else{
                        this.list = res.data.data;
                        this.total = res.data.total;
                        this.pageToatal = Math.ceil(this.total / this.options.pageSize)
                    }
                }
            })
        },
        //编辑按钮
        edit(id){
            this.dialogVisible = true;
            this.questionsId = id;
            this.content = ''
            this.getReply({questionsId:this.questionsId})
        },
        //dialog的确定
        dialogCommit(){
            if(this.content == ''){
                this.$message({
                    message:'回复内容为空！',
                    type:'error'
                })
                return;
            }
            this.$api.addReply({
                id: this.questionsId,
                content:this.content
            }).then(res=>{
                this.dialogVisible = false;
                if(res.data.code == 200){
                    this.$message({
                        type: 'success',
                        message: '回复成功!'
                    });
                }else{
                    this.$message({
                        type: 'info',
                        message: '回复失败！'
                    }); 
                }
            })
        },
        //搜索
        search(){
            this.getQuestions(this.options);
            this.pageCurrent = 1
        },
        //重置
        reset(){
            this.options = {
                pageSize:15,
                pageIndex:1,
                companyName:'',
            }
            this.pageCurrent = 1
            this.getQuestions(this.options)
        },
        //删除问题
        deleteQuestions(id){
            this.$confirm('此操作将删除该文章, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.deleteQuestions({id}).then(res=>{
                    if(res.data.code == 200){
                        this.getQuestions(this.options)
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }else{
                        this.$message({
                            type: 'info',
                            message: '删除失败！'
                        }); 
                    }
                })
            })
        },
        //获取回复
        getReply(options){
            this.$api.getReply(options).then(res=>{
                this.replyList = res.data.data;
            })
        },
        //删除回复
        deleteReply(id){
            this.$confirm('此操作将删除该回复, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.deleteReply({id}).then(res=>{
                    if(res.data.code == 200){
                        this.getReply({questionsId:this.questionsId})
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }else{
                        this.$message({
                            type: 'info',
                            message: '删除失败！'
                        }); 
                    }
                })
            })
        },
        //上一页
        prev(){
            if (this.options.pageIndex <= 1) return;
            this.options.pageIndex --;
            this.pageCurrent = this.options.pageIndex
            this.getQuestions(this.options);
        },
        //下一页
        next(){
            if(this.pageCurrent >= this.pageToatal) return
            this.options.pageIndex ++;
            this.pageCurrent = this.options.pageIndex
            this.getQuestions(this.options);
        },
    },
    mounted(){
        this.getQuestions(this.options);
    },
}
</script>

<style lang="scss" scoped>
.contianer{
    background: #fff;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    padding: 0.7rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.head{
    height: 5%;
    display: flex;
    align-items: center;
    .title{
        color: #4D4D4D;
        font-size: 15px;
        margin-right: 5px;
    }
    .el-input,.el-select{
        width: 12%;
        margin-right: 1.5%;
    }
    .el-button{
        margin: 0 0.7%;
    }
}
.table-box{
    flex: 1;
    margin-top: 1%;
    table{
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        height: 100%;
        .head-tr{
            background: rgba($color: #eee, $alpha: 0.9);
        }
        tr{
            transition: all 0.3s ;
            td{
                height: 45px;
                border: 1px solid #eee;
                text-align: center;
                color: #4D4D4D;
                font-size: 15px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        tr:hover{
            background:  rgba($color: #eee, $alpha: 0.3);;
        }
    }
}
.footer{
    //border: 1px solid red;
    height: 6%;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    div{
        cursor: pointer;
    }
    div:hover{color: #409EFF;}
    p{
        margin: 0 10px;
        color: #409EFF
    }
}
.el-dialog{
    .dia-table-box{
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
    }
    table{
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        height: 100%;
        .head-tr{
            background: rgba($color: #eee, $alpha: 0.5);
        }
        tr{
            transition: all 0.3s ;
            height: 40px;
            td{
                border: 1px solid #eee;
                text-align: center;
                color: #4D4D4D;
                font-size: 15px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                i{
                    color: red;
                    font-size: 19px;
                    cursor: pointer;
                }
            }
        }
        tr:hover{
            background:  rgba($color: #eee, $alpha: 0.1);;
        }
    }
    .dialog-form{
        display: flex;
        margin-top: 3%;
        div{white-space: nowrap;}
        textarea{
            width: 95%;
            margin-left: 2%;
            border: 1px solid #ccc;
            outline: none;
            height: 100px;
            background: none;
            padding: 3px;
            border-radius: 4px;
            font-size: 15px;
        }
    }
}
</style>